













































































































import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { ApiService } from '@/core/services';
import {
  ReporterAccessRequestLookupModel,
  ReporterAccessRequestOrganisationModel,
  ReporterOrganisationAccessRequestModel,
} from '@/core/webapi';
import { InputFieldCpt, ValidationSummaryCpt, ValidationHintCpt, SpinnerCpt } from '@/core/components';
import { InputOrganisationPermissionsCpt, InputOrganisationStudiesCpt } from '@/modules/admin/users/components';
import { InputOrganisationCommentsAndActionsCpt } from './components';

@Component({
  components: {
    InputFieldCpt,
    InputOrganisationPermissionsCpt,
    InputOrganisationCommentsAndActionsCpt,
    InputOrganisationStudiesCpt,
    ValidationSummaryCpt,
    ValidationHintCpt,
    SpinnerCpt,
  },
})
export default class ReporterOrganisationAccessRequestView extends AppVue {
  lookups = {} as ReporterAccessRequestLookupModel;
  model = new ReporterOrganisationAccessRequestModel({
    modelState: {},
  } as any);
  loading = true;
  submitInProgress = false;

  internalTimestamp = new Date().toString();

  // NOTE: Using this nonsense hack to force refresh on modelState errors because this.$forceUpdate() is not enough here
  get timestamp() {
    return this.internalTimestamp;
  }

  get editableAccessLevelLabel() {
    return this.$t(
      this.isReporter
        ? 'Required access level within the organisation'
        : 'Requested access level within the organisation',
    );
  }

  get isRequestMedicalAdminApproved() {
    return this.model.requestOrganisationPermissions.isMedicalAdminApproved;
  }

  async created() {
    try {
      this.model =
        this.$route.name === 'organisationAccessRequest'
          ? (
              await ApiService.reporterAccess().getOrganisationReporterAccessRequest(
                +this.$route.params.organisationId,
                this.currentUserId!,
              )
            ).data
          : (
              await ApiService.reporterAccess().getOrganisationReporterAccessRequest(
                +this.$route.query.organisationId,
                +this.$route.query.reporterUserId,
              )
            ).data;

      this.lookups = (await ApiService.reporterAccess().getReporterAccessRequestLookupData()).data;
    } finally {
      this.loading = false;
    }
  }

  async requestChanges() {
    this.submitInProgress = false;
    try {
      if (!this.model.requestOrganisationPermissions.organisationRequestId) {
        await ApiService.reporterAccess()
          .createOrganisationReporterAccessRequest(this.model.requestOrganisationPermissions)
          .handleValidationErrors(this.model.requestOrganisationPermissions);
      } else {
        await ApiService.reporterAccess()
          .updateOrganisationReporterAccessRequest(this.model.requestOrganisationPermissions)
          .handleValidationErrors(this.model.requestOrganisationPermissions);
      }

      this.$notify({
        title: this.$t('Success') as string,
        message: this.$t('Request submitted') as string,
        type: 'success',
      });

      this.$router.push('/profile');
    } finally {
      this.submitInProgress = false;
      // Have to do this re-mapping to break the reference which then triggers "Access from" and "Access to" validation
      (this.model as any).modelState = { ...(this.model as any).modelState };
      this.internalTimestamp = new Date().toString();
      this.$forceUpdate();
    }
  }

  async approveRequest() {
    this.submitInProgress = true;
    try {
      await ApiService.reporterAccess().approveOrganisationReporterAccessRequest(
        this.model.requestOrganisationPermissions,
      );

      this.$notify({
        title: this.$t('Success') as string,
        message: this.$t('Organisation access request approved') as string,
        type: 'success',
      });

      this.$router.push('/admin/reporter-access-requests');
    } finally {
      this.submitInProgress = false;
    }
  }

  async saveOrganisationRequest(organisation: ReporterAccessRequestOrganisationModel) {
    this.loading = true;
    try {
      await ApiService.reporterAccess()
        .updateOrganisationMedicalAdminStatus(organisation)
        .handleValidationErrors(organisation);

      this.$notify({
        title: this.$t('Success') as string,
        message: this.$t('Organisation access request updated') as string,
        type: 'success',
      });

      this.$router.push('/admin/reporter-access-requests');
    } finally {
      this.loading = false;
    }
  }

  async rejectOrganisationRequest(organisationRequestId: number) {
    this.$prompt('Please provide rejection reason', 'Reject request', {
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel',
    })
      .then(async (result: any) => {
        this.loading = true;

        try {
          await ApiService.reporterAccess().rejectReporterOrganisationAccessRequest({
            organisationRequestId,
            rejectionReason: result.value,
          });

          this.$notify({
            title: this.$t('Success') as string,
            message: this.$t('Organisation access request rejected') as string,
            type: 'success',
          });

          this.$router.push('/admin/reporter-access-requests');
        } finally {
          this.loading = false;
        }
      })
      .catch(_ => {});
  }
}
